.services-header {
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 2.75em;
  font-weight: 550;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  align-items: center;
  padding-left: 10px;
  color: #0d1643;
}
* {
  box-sizing: border-box;
}

.column {
  margin-top: 10px;
  align-content: center;
  float: left;
  width: 20%;
  padding: 5px;
}
.row {
  align-items: center;
  padding: 5px;
  display: flex;
  /*margin-left: 230px; */
}

.service-card {
  width: 300px;
  height: 400px;
}

.services-container {
  margin-bottom: 50px;
}

.service-card {
  position: relative;
}

.service-card-text {
  position: absolute;
  bottom: 20px;
  margin-bottom: 20px;
  width: 100%;
  font-weight: 700;
  font-size: 2.25em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  align-items: center;
  color: white;
}

.service-card:hover {
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  box-shadow: 12px 12px #0d1643;
}

/* For sub-sections within Services (e.g. Trucking) */
.services-text {
  text-align: left;
}

.services-text li {
  margin-bottom: 8px;
}

{/*
.services-picture {
  padding-bottom: 50px;
}

.services-picture img {
  box-shadow: -18px 18px #0d1643;
  width: -webkit-fill-available;
  max-width: 1000px;
}
*/}
@media screen and (min-width: 800px) {
  .services-text {
    padding: 5rem;
    font-size: 20px;
  }
}

@media screen and (max-width: 800px) {
  .services-text {
    font-size: 17px;
  }

  .services-text h3 {
    text-align: center;
  }
}
