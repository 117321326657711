.dinfo-header{
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 2.75em;
    font-weight: 550;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-items: center;
    padding-left: 10px;
    color: #0D1643;
}

.top-line {
    font-size: 1rem !important;
}

.container-text {
    height: auto;
    padding-bottom: 20px;
}

.divider-middle {
    align-content: center;
    margin: 20px auto;
}

@media (max-width: 800px) {
    .aboutus-container {
        max-width: 95vw;
    }
    .container-text {
        max-width: 100%;

    }
}
@media (max-width: 650px) {
    .aboutus-container {
        max-width: 71vw;
    }
    .container-text {
        max-width: 100%;

    }
    
}
@media (max-width: 450px) {
    .aboutus-container {
        max-width: 61vw;
    }
    .container-text {
        max-width: 100%;

    }
    
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .aboutus-container {
        max-width: 40vw;
    }
    .container-text {
        max-width: 100%;

    }
}