* {
    box-sizing: border-box;
    
}

.header {
    margin-top: 120px;
    font-size: 20px;
}

body {
	padding: 15px;
}

.board-header{
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 2.5em;
    font-weight: 550;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-items: center;
    padding-left: 10px;
    color: #0D1643;
}

.board-container {
    max-width: 90vw;
}
@media only screen and (max-width: 600px) {
    .board-container {
        max-width: 95vw;
    }
    .board-image {
        width: 50px;
    }
    .board-header {
        margin-bottom: 20px;
        font-size: 1.5em;
    }
}
@media only screen and (min-width: 992px) {
    .board-container {
        max-width: 71vw;
    }
    .board-image {
        width: 50px;
    }
}
@media only screen and (min-width: 1200px) {
    .board-container {
        max-width: 61vw;
    }
   
}

@media only screen and (max-width: 500px) {
    .board-container {
        max-width: 100vw;
    }
    .summary {
        vertical-align: text-bottom !important;
        /* width: 100% !important; */
        width: 100%;
        display: inherit !important;
    }

    .img-container {
        padding-left:10%;
        padding-right:15%;
        display: block;
        text-align: center;
    }
    .board-image {
        /* width: 100% !important; */
        width: 100%;
        border-radius: 25px;
    }
    .board-header {
        margin-bottom: 20px;
        font-size: 1.5em;
    }
}

.board-image {
    width: 25%;
    margin: 1%;
    border-radius: 25px;
    float: left;
}


.board-content {
    text-align: left;
    height: auto;
}


.name {
    font-size: 1.7em;
    font-weight: 700;
    color: #0D1643;
}

.job-title {
    font-size: 1.2em;
    font-weight: 550;
    font-style: italic;
    color: #F78437;
    margin-top: 4px;
    margin-bottom: 8px;
}

.summary {
    width: 70%;
    float: right;
    text-align: left;
    font-size: 1.2em;
}

.board-li {
    margin-left: 2%;
    margin-top: 2%;
    margin-bottom: 2%;
    line-height: 150%;
}

.divider-middle {
    align-content: center;
    width: 100%;
}

