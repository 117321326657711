.home {
  margin-top: 60px;
  width: 100%;
  height: 62vh;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  position: relative;
}



.buttonContainer {
  position: absolute;
  bottom: 5vh;
  left: 40px;
  width: 170px;
}

.buttonContainer-quote {
  /* position: absolute; */
  bottom: 5vh;
  right: 130px;
  width: 170px;
  margin: 10px auto;
}

.buttonContainer button {
  display: block;
  padding: 4px 0px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 255);
  backdrop-filter: blur(30px);
  color: rgba(255, 255, 255, 255);
  font-size: 15px;
  letter-spacing: 1px;
  width: 300px;
  cursor: pointer;
}

.buttonContainer-quote button {
  /* display: block; */
  padding: 4px 0px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 255);
  /* backdrop-filter: blur(30px); */
  color: rgba(255, 255, 255, 255);
  font-size: 15px;
  letter-spacing: 1px;
  width: 300px;
  cursor: pointer;
}

.buttonContainer button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgb(255, 132, 47);
  color: rgb(255, 132, 47);

}

.buttonContainer-quote button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgb(255, 132, 47);
  color: rgb(255, 132, 47);

}

.loginContainer {
  position: absolute;
  top: 5vh;
  left: 40px;
  width: 170px;
}

.loginContainer button {
  display: block;
  padding: 4px 0px;
  border-radius: 5px;
  background-color: #0d1643;
  border: 1px solid rgba(255, 255, 255, 255);
  backdrop-filter: blur(30px);
  color: rgba(255, 255, 255, 255);
  font-size: 15px;
  letter-spacing: 1px;
  width: 300px;
  cursor: pointer;
}

.loginContainer button:hover {
  background-color: #0d1643;
  border-color: rgb(255, 132, 47);
  color: rgb(255, 132, 47);

}

.react-responsive-modal-modal {
  max-width: 1200px;
  /* max-width: 1200px !important; */
}

@media only screen and (max-width: 650px) {
  .home {
    justify-content: center;
    align-items: center;
  }

  .buttonContainer {
    position: absolute;
    bottom: 5vh;
    left: 10px;
    width: 170px;
  }


  .buttonContainer {
    display: inline-block;
    padding: 6px 14px;
    border: 3px;
    border-radius: 5px;
    /* backdrop-filter: blur(30px); */
    color: rgba(255, 255, 255, 255);
    font-size: 12px;
    letter-spacing: 2px;
    cursor: pointer;
  }

  .butonContainer:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-color: rgb(255, 132, 47);
    color: rgb(255, 132, 47);
  }

  .buttonContainer-quote {
    display: inline-block;
    padding: 6px 14px;
    border: 3px;
    border-radius: 5px;
    backdrop-filter: blur(30px);
    color: rgba(255, 255, 255, 255);
    font-size: 12px;
    letter-spacing: 2px;
    cursor: pointer;
  }

  .butonContainer-quote:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-color: rgb(255, 132, 47);
    color: rgb(255, 132, 47);
  }

  .loginContainer {
    /* position: absolute;
      top: 5vh;
      left: 40px;
      width: 170px; */
    display: inline-block;
    padding: 6px 14px;
    border: 3px;
    border-radius: 5px;
    /* backdrop-filter: blur(30px); */
    color: rgba(255, 255, 255, 255);
    font-size: 12px;
    letter-spacing: 2px;
    cursor: pointer;
  }

  .loginContainer button {
    /* display: block;
      padding: 4px 0px;
      border-radius: 5px;
      background-color: #0d1643;
      border: 1px solid rgba(255, 255, 255, 255);
      backdrop-filter: blur(30px);
      color: rgba(255, 255, 255, 255);
      font-size: 15px;
      letter-spacing: 1px;
      width: 300px;
      cursor: pointer; */
    display: inline-block;
    padding: 6px 14px;
    border: 3px;
    border-radius: 5px;
    backdrop-filter: blur(30px);
    color: rgba(255, 255, 255, 255);
    font-size: 12px;
    letter-spacing: 2px;
    cursor: pointer;
  }

  .loginContainer button:hover {
    background-color: #0d1643;
    border-color: rgb(255, 132, 47);
    color: rgb(255, 132, 47);

  }
}