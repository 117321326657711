
.forms-header{
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 2.75em;
    font-weight: 550;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-items: center;
    padding-left: 10px;
    color: #0D1643;
}

.forms {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
}
.pdficon {
    color: rgb(247, 132, 55);
    width: 20px;
    height: auto;
}

.ListItemText {
    font-weight: bold;
}

.pdf-name a{
    font-weight: bold;
    color: #000;
    text-decoration: none;
    font-size: 1.375rem !important;
}

.pdf-name a:hover {
    color: rgb(247, 132, 55);
    transition: 0.3s ease-out;
}

.pdficon:hover {
    color: black;
}

.forms-container { 
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 85px;
}




.quote-button button:hover {
    border-color: rgb(247, 132, 55);
    color:rgb(247, 132, 55);
    justify-content: center;
}

@media screen and (max-width: 820px) {
    .forms {
        width: 200px;
        

    }

    .forms-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        margin-left: auto;
        margin-right: -205px;
    }

    .pdf-name a{
        font-size: 1.2em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pdf-icon {
        font-size: small;
    }
}