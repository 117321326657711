body {
  padding: 0;
  margin: 0;
}

.navbar {
    background: #0D1643;
    height: 65px;
    display: flex; 
    justify-content: left;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1030;
}

.navbarContainer {
  width: 50%;
  padding-left: 0%;
  display: flex;
}

.navbar .row {
  width: 100vw;
}

.navbar .rightSide {
 flex: 70%;
 height: 100%;
 display: flex;
 margin-top: 15px;
 padding-left: 5px;
 display: flex;
 padding-left: 20px;
 font-weight: bold;
 font-size: 1rem;
}

.navbar .leftSide img {
  width: 307px;
}

.navbar .leftSide a {
  font-size: 1rem !important;
}

.button {
  font-weight: bold;
  border: 1px solid;
  border-color: #fff;
  padding: 0.25rem 0.2rem;
  text-align: right;
  background-color: transparent;
  color: #fff;
  margin-left: 0.625rem;
  font-size: 1rem !important;
}

.button:hover {
  border-color: rgb(247, 132, 55);
  color: rgb(247, 132, 55);
}

.button:active {
  background-color: #0D1643;
  box-shadow: 0 0.3125rem #666;
  transform: translateY(0.25rem);
}

.navbar a {
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  text-decoration: bold;
  padding: 0 0.3125rem;
  font-size: 0.875rem;
  height: 100%;
}

.titaniumLogo {
    width: 1.25rem;
    align-items: left;
    height: 100%;
}

.navbar a:hover {
  color: rgb(247, 132, 55);
  transition: all 0.2s ease-out;
}

.navbar .leftSide {
    width: 307px;
}

.float-right {
  display: flex;
  font-size: 1rem;
}

#rightMenu {
  width: 50%;
}

#navMenu.rightSide {
  font-size: 1rem !important;
}

#navMenu.rightSide a {
  font-size: 1rem !important;
}

.float-right a button {
  font-size: 1rem !important;
}

.float-right .menu-bars {
  font-size: 1rem !important;
}

@media only screen and (min-width: 1300px) {
  .navbar .float-right .menu-bars {
    display: none;
  }
  #menu {
    display: none;
  }
}

@media only screen and (max-width: 1300px) {
  .menu-icon {
    font-size: 2em;
  }

  .navbar .rightSide a {
    display: none;
  }
  .navbar .rightSide button {
    display: none;
    border: none;
  } 
  .navbar .float-right button {
    display: none;
  }

  .navbar .float-right .menu-bars {
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  #navMenu {
    display: none;
    border: none;
  }
  .float-right {
    margin-right: 0%;
    padding-right: 0%;
  }
}

@media only screen and (max-width: 600px) {
  .navbar .leftSide {
    padding-left: 1%;
  }

  #navMenu {
    display: none;
    border: none;
  }

  .float-right {
    margin-right: 0%;
    padding-right: 0%;
  }
}

@media only screen and (max-width: 500px) {
  .menu-icon {
    font-size: 1.5em;
  }

  .navbar .leftSide img {
    width: 200px;
  }

  .float-right {
    margin-right: 0%;
    padding-right: 0%;
  }
}
