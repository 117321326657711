.fr-header{
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 2.75rem;
    font-weight: 550;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-items: center;
    padding-left: 10px;
    color: #0D1643;
}

.fr-subheader {
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 1.50rem;
    font-weight: 200;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-items: center;
    padding-left: 10px;
    color: #000000;
}

.rectangle {
    width: 1000px;
    height: auto;
    padding-top: 10px;
    outline: solid #0D1643;
    align-self: center;
}

.rec-pre {
    outline: #0D1643;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row-container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.report-placeholder{
    pointer-events: none;
    color: #ccc !important;
}

.report-name a{
    font-weight: bold;
    color: #000;
    text-decoration: none;
    font-size: 1.5625rem !important;
    
}

.report-name a:hover {
    color: rgb(247, 132, 55);
}

.disclaimer {
    margin-top: 20px;
    font-size: 0.9375rem !important;
    font-weight: bold;
    color: #0D1643;
}

@media screen and (max-width: 820px) {
    .rectangle {
        width: 300px;
        
    }
    .rec-pre {
        margin-top: 10px;
    }
    .report-name {
        font-size: 0.625rem !important;
    }
    .report-name a {
        font-size: 0.625rem !important;
    }
}