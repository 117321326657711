.contact-header{
    margin-top: 20px;
    margin-bottom: 20px;
    /* font-size: 2.75em; */
    font-size: 2.75rem !important;
    font-weight: 550;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-items: center;
    padding-left: 10px;
    color: #0D1643;
}
.align-items-first {
    width: 100%;
    margin-top: 0px;
    position: sticky;
}

.contact-info {
    text-align: left;
}

.department-header {
    color: #f78437;
}

.department-header h4 {
    font-size: 1rem !important; /* 1rem equals 16px */
}
.department-header a {
    font-size: 1rem !important; /* 1rem equals 16px */
}

.sub-department {
    color: #0D1643;

}

.contact a {
    font-size: 1rem !important; /* 1rem equals 16px */
}

.sub-department h5 {
    font-size: 1rem !important; /* 1rem equals 16px */
}

.contact {
    color: black;
}

.contact h8 {
    font-size: 1rem !important; /* 1rem equals 16px */
}

.contacts-container {
    height: auto;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

@media only screen and (min-width: 992px) {
    .contacts-container {
        max-width: 75vw;
        margin-right: auto;
        margin-left: auto;
    }
    
}
