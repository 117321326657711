button {
    font-size: 1.125rem !important;
}

.carousel-status{
    font-size: 0.625rem !important;
}

@media screen and (max-width: 1000px) {
    #technology-image-wrap{
        width: 100%;
    }
    #technology-description-wrap {
        width: 100%;
    }
}

@media screen and (min-width: 1000px) {
    #technology-image-wrap{
        width: 50%;
    }
    #technology-description-wrap {
        width: 50%;
    }
}