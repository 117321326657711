.board-header {
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 2.5em;
  font-weight: 550;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  align-items: center;
  padding-left: 10px;
  color: #0d1643;
}

.aboutus-text {
  margin-top: 50px;
  width: 73%;
  display: block;
  /* font-size: 19px; */
  font-size: 1.1875rem !important;

}

.aboutus-text p {
  text-align: justify;
}

.sub-header {
  margin-top: 60px;
  margin-bottom: 30px;
  font-size: 2.25em;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  align-items: center;
  padding-left: 10px;
  color: #0d1643;
}

@media screen and (max-width: 800px) {
  /*For smaller screens*/
  .aboutus-container {
    max-width: 95vw;
  }
}
