.quote-header{
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 2.5rem;
    font-weight: 550;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-items: center;
    padding-left: 10px;
    color: #0D1643;
}

@media only screen and (max-width: 500px) {
    .quote-header{
        font-size: 2rem;
    }
}


.quote-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -45px;
}
.form-input:label {
    width: 100%;
}
.ui-button {
    display: inline-block;
    font-weight: bold;
    padding: 6px 14px;
    border: 4px;
    border-radius: 3px;
    background-color: #ffffff;
    border: 2px solid #0D1643;
    color: #0D1643;
    font-size: 1.125rem !important;
    letter-spacing: 2px;
    cursor: pointer;
    margin-bottom: 30px;
    display: 'flex';
    margin-left: 419px;
    margin-top: 15px;
  }

.ui-button:hover {
    border-color: rgb(247, 132, 55);
    color: rgb(247, 132, 55);
    background-color: #ffffff;
  }

.form label {
    margin-left: 60px;
}


.form-style-1 {
	margin:10px auto;
	max-width: 400px;
	padding: 20px 12px 10px 20px;
	font-size: 0.8125rem !important;
    font-family: Arial, Helvetica, sans-serif;

}
.form-style-1 li {
	padding: 0;
	display: block;
	list-style: none;
	margin: 10px 0 0 0;
}
.form-style-1 label{
	margin:0 0 3px 0;
	padding:0px;
	display:block;
	font-weight: bold;
}
.form-style-1 input[type=text], 
.form-style-1 input[type=date],
.form-style-1 input[type=datetime],
.form-style-1 input[type=number],
.form-style-1 input[type=search],
.form-style-1 input[type=time],
.form-style-1 input[type=url],
.form-style-1 input[type=email],
textarea, 
select{
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	border:1px solid #BEBEBE;
	padding: 7px;
	margin:0px;
	-webkit-transition: all 0.30s ease-in-out;
	-moz-transition: all 0.30s ease-in-out;
	-ms-transition: all 0.30s ease-in-out;
	-o-transition: all 0.30s ease-in-out;
	outline: none;	
}
.form-style-1 input[type=text]:focus, 
.form-style-1 input[type=date]:focus,
.form-style-1 input[type=datetime]:focus,
.form-style-1 input[type=number]:focus,
.form-style-1 input[type=search]:focus,
.form-style-1 input[type=time]:focus,
.form-style-1 input[type=url]:focus,
.form-style-1 input[type=email]:focus,
.form-style-1 textarea:focus, 
.form-style-1 select:focus{
	-moz-box-shadow: 0 0 8px #f78437;
	-webkit-box-shadow: 0 0 8px #f78437;
	box-shadow: 0 0 8px #f78437;
	border: 1px solid #f78437;
}

.form-style-1 .field-long{
	width: 300px;
}

.form-style-1 .field-textarea{
	height: 100px;
}
.form-style-1 input[type=submit], .form-style-1 input[type=button]{
    margin-top: 15px;
	background: #fff;
	padding: 4px 7px 4px 7px;
	border-color: #0D1643;
	color: #0D1643;
    font-weight: bold;
    font-size: 0.875rem !important;

    border-radius: 3px;
}
.form-style-1 input[type=submit]:hover, .form-style-1 input[type=button]:hover{
	border-color:rgb(247, 132, 55);
    color:rgb(247, 132, 55);
	box-shadow:none;
	-moz-box-shadow:none;
	-webkit-box-shadow:none;
}

.form-style-1 input{
    font-size: 1rem !important;
}