.news-header{
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 2.5rem;
  font-weight: 550;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  align-items: center;
  padding-left: 10px;
  color: #0D1643;
}


  .ul{
    list-style:none;
    position:relative;
  }
  .li{
    height:100px;
    /*background-color:rgba(57, 173, 117,0.3);*/
    text-align:center;
  }
  .h2{
    color:#fff;
    padding-top:10px;
  }
  .p{
    text-align:left;
    padding:10px;
    color:#eee;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
   }

.news-ticker-container {
    align-items: center;
    align-self: center;
    overflow:hidden;
    margin:20px auto;
    height:300px;
    /* width: 61%w !important; */
    width: 61%;
}

.news-all-container {
    align-items: center;
    align-self: center;
    overflow:hidden;
    margin:20px auto;
    /* width: 75% !important; */
    width: 75%;
}

.newsticker {
    color: #0D1643;
}

#subscribe-icon {
    display: none;
}


@media only screen and (max-width: 600px) {
    .news-ticker-container {
        width: 95%;
        /* width: 95% !important; */
    }
    .subscribe-button {
        display: none;
    }

    #subscribe-icon {
        display: block;
        color: #0D1643;
        font-weight: bold;
        font-size: 2rem;
        background-color: #fff;
        float: right;
        width: auto;
        border-color: #0D1643;
        border-radius: 4px;
    }

    #subscribe-icon:hover {
        color: #F78437;
        border-color: #F78437;
    
    }
}
@media only screen and (min-width: 992px) {
    .news-ticker-container {
        width: 71%;
        /* width: 71% !important; */
    }
}
@media only screen and (min-width: 1200px) {
    .news-ticker-container {
        width: 61%;
    }
}

.top-news {
    height: 100px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.top-news-heading {
    font-size: 1.0rem;
    font-weight: 700;
    color: #000;
    text-align: left;
    margin-top: 4px;
}

.top-news-date {
    font-size: 0.9rem;
    font-weight: 550;
    color: #F78437;
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: left;
}

.top-news-body {
    font-size: 1rem;
    font-weight: 400;
    color: #000;
    text-align: left;
}


.all-news {
    height: auto;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 0px;
    margin: 2px 0px;
}

.all-news-heading {
    font-size: 1.0rem;
    font-weight: 700;
    color: #000;
    text-align: left;
    margin-top: 4px;
}

.all-news-date {
    font-size: 0.9rem;
    font-weight: 550;
    color: #F78437;
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: left;
}

.read-more{
    font-size: 0.9rem;
    font-weight: 550;
    color: #F78437;
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: right;
    width: 100%;
}

.subscribe-button {
    color: #0D1643;
    font-weight: bold;
    font-size: 0.9375rem !important;
    background-color: #fff;
    padding: 3px 6px;
    float: right;
    width: auto;
    border-color: #0D1643;
    border-radius: 4px;
}

.news-header {
    display: inline-block;
}
.subscribe-button:hover {
    color: #F78437;
    border-color: #F78437;

}