.investors-header {
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 2.75rem !important;
    font-weight: 550;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-items: center;
    padding-left: 10px;
    color: #0D1643;

}

.tradingview-widget-copyright-AA a,
.tradingview-widget-copyright-AA span {
    font-size: 0.8125rem !important;
}


#inventor-top {
    align-items: center;
    width:90%;
    margin-left: 5%;
    margin-right: 5%;
}

.icons-list-align-left {
    outline: 2px solid #0D1643;
    background-color: #0d1643    ;
    width: 20%;
    float: left;
    font-size: 1.3rem !important;
    line-height: 200%;
}

.icons-list-align-left a {
    color: white;
    font-weight: bold;
    text-align: left;
}

.icons-list-align-left a:hover {
    color: #f78437;
    font-weight: bold;

}

.icons-list-align-left img {
    color: #0D1643;
    height: 40px;
    display: inline-block;
    margin-top: 10%;
    margin-bottom: 10%;
}
.icons-list-align-left ul {
    margin-left: 0%;
    padding-left: 0%;
}

.contact-images-container {
    outline: 2px solid #0d1643;
    width: 80%;
    margin: auto;
    /* margin-left: 5%; */
    
}
/* .contact-images-container div>img {
    width: 40%;
    margin: auto;
    display: inline-block;
} */

.tradingview-widget-container {
    /* margin: auto; */
    width: 60%;
    float: right;
    margin-right: 10%;
    /* display: inline-block; */
}

.form-subheader {
    text-align: center;
    font-size: 2.2rem !important;
    font-weight: bold;
    color:rgb(247, 132, 55);
    margin-top: 50px;

}

.contact-subheader {
    text-align: center;
    font-size: 2.2rem !important;
    font-weight: bold;
    color:rgb(247, 132, 55);
    margin-top: 50px;

}

.quote-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -45px;
}

.ui-button {
    display: inline-block;
    font-weight: bold;
    padding: 6px 14px;
    border: 4px;
    border-radius: 3px;
    background-color: #ffffff;
    border: 2px solid #0D1643;
    color: #0D1643;
    font-size: 1.125rem !important;
    letter-spacing: 2px;
    cursor: pointer;
    margin-bottom: 30px;
    display: 'flex';
    margin-left: 419px;
    margin-top: 15px;
  }

.ui-button:hover {
    border-color: rgb(247, 132, 55);
    color: rgb(247, 132, 55);
    background-color: #ffffff;
  }



.form-style-1 {
	margin:10px auto;
	max-width: 60vw;
	padding: 20px 12px 10px 20px;
	font-size: 0.8125rem !important;
    font-family: Arial, Helvetica, sans-serif;

}
.form-style-1 li {
	padding: 0px;
	display: block;
	list-style: none;
	margin: 10px 0 0 0;
}
.form-style-1 label{
	margin:0 0 5px 0;
	padding-top:0px;
	display:block;
	font-weight: bold;
    font-size: 1rem !important;
    text-align: left;

}
.form-style-1 input[type=text], 
.form-style-1 input[type=date],
.form-style-1 input[type=datetime],
.form-style-1 input[type=number],
.form-style-1 input[type=search],
.form-style-1 input[type=time],
.form-style-1 input[type=url],
.form-style-1 input[type=email],
textarea, 
select{
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	border:1px solid #BEBEBE;
	padding: 7px;
	margin:0px;
	-webkit-transition: all 0.30s ease-in-out;
	-moz-transition: all 0.30s ease-in-out;
	-ms-transition: all 0.30s ease-in-out;
	-o-transition: all 0.30s ease-in-out;
	outline: none;	
}
.form-style-1 input[type=text]:focus, 
.form-style-1 input[type=email]:focus,
.form-style-1 textarea:focus, 
.form-style-1 select:focus{
	-moz-box-shadow: 0 0 8px #f78437;
	-webkit-box-shadow: 0 0 8px #f78437;
	box-shadow: 0 0 8px #f78437;
	border: 1px solid #f78437;
}

.form-style-1 .field-long{
	width: 40vw;
}

.form-style-1 .field-textarea{
	height: 140px;
}
.form-style-1 input[type=submit], .form-style-1 input[type=button]{
    margin-top: 20px;
	background: #fff;
	padding: 4px 14px 4px 14px;
	border-color: #0D1643;
	color: #0D1643;
    font-weight: bold;
    font-size: 1rem !important;

    border-radius: 3px;
}
.form-style-1 input[type=submit]:hover, .form-style-1 input[type=button]:hover{
	border-color:rgb(247, 132, 55);
    color:rgb(247, 132, 55);
	box-shadow:none;
	-moz-box-shadow:none;
	-webkit-box-shadow:none;
}



@media screen and (max-width: 1000px) {
    /*For smaller screens*/
    .icons-list-align-left {
        margin: auto;
    }

}

@media screen and (max-width: 900px) {
    .analyst-header {
        text-align: center;
        margin-right: 300px;
        
    }
    .analyst-coverage img {
        width: 280px;
        margin-left: -600px;
        align-items: left;
     
    }
    .coverage-image {
        width: 90%;
    }
}
  

@media screen and (max-width: 800px) {
    /*For smaller screens*/
    .icons-list-align-left {
      max-width: 95vw;
    }

    .tradingview-widget-container {
        margin-right: auto;
        margin-left: auto;
    }


  }

  @media screen and (max-width: 600px) {
    .analyst-header {
        text-align: center;
        margin-right: 300px;
        
    }
    .analyst-coverage img {
        width: 280px;
        margin-left: -600px;
        align-items: left;
    }

    .icons-list-align-left {
        /* display: flex; */
        float: none;
        width: 100%;
        margin-bottom: 10%;
    }

    .tradingview-widget-container {
        /* display: flex; */
        float: none;
        width: 100%;
    }

}

  