.popup-box {
    position: fixed;
    background: #00000070;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
   
  .box {
    position: relative;
    width: 40%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #0D1643;
    border-radius: 4px;
    padding: 20px;
    border: 2px solid #f78437;
    overflow: auto;
    color: #fff;

  }

  @media only screen and (max-width: 1200px) {
    .box {
      width: 50%;
    }
  }

  
  @media only screen and (max-width: 900px) {
    .box {
      width: 60%;
    }
  }

  @media only screen and (max-width: 600px) {
    .box {
      width: 100%;
    }
  }
   
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: absolute;
    top: -1px;
    right: -1px;
    background: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #fff;
    font-size: 20px;
    color: #0D1643;
  }

  .btn-outline1 {
    background-color: rgb(247, 132, 55);
    color: #fff;
    border-color: rgb(247, 132, 55);
    margin-right: auto;
    margin-left: auto;
    padding: 8px 20px;
    font-weight: bold;
    font-size: 18px;
    border-radius: 3px;
    transition: all 0.3s ease-out;
    /* width: 440px !important; */
    width: 440px;
  }
  
  .btn-outline1:hover {
    color: rgb(247, 132, 55);
    background-color: #fff;
    border-color: #fff;
  }

  .row {
    margin-bottom: 10px;
  }

  .popup-header {
    font-weight: bold;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }