.mission-header {
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 2.75em;
    font-weight: 550;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-items: center;
    padding-left: 10px;
    color: #0D1643;
}

.mission-subheader {
    color: #f78437;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    font-weight: bold;
    font-size: 2em;
    margin-bottom: 15px;
    letter-spacing: 1px;
}

.mission-sub-subheader {
    color: #0D1643;
    font-size: 1.5em;
    font-weight: bold;
    font-style: italic;
    text-align: left;
    margin-right: auto;
    margin-left: auto;
    width: 75%;
    letter-spacing: 1px;
    margin-bottom: 5px;
    
}

.mission-text {
    color: #000;
    margin-right: auto;
    margin-left: auto;
    width: 75%;
    text-align: justify;
    font-size: 1.1em;
    margin-bottom: 5px;
}