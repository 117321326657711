.login-header{
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 2.75em;
    font-weight: 550;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-items: center;
    padding-left: 10px;
    color: #0D1643;
}
* {
    box-sizing: border-box;
  }
  
.column {
    margin-top: 10px;
    align-content: center;
    float: left;
    width: 20%;
    padding: 5px;
  }
.row {
    align-items: center;
    padding: 5px;
    display: flex;
    /*margin-left: 230px; */
}

.login-card {
    width: 300px;
    height: 400px;
    background-size: cover;
}
 

.login-container {
    margin-bottom: 50px;
}



.login-card {
    position: relative;
}

.login-card-text  {
    position: absolute;
    bottom: 20px;
    margin-bottom: 20px;
    width: 100%;
    font-weight: 700;
    font-size: 2.2em;
    line-height: 90%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-items: center;
    color: white;
}

.login-card:hover {
    -o-transition:.3s;
    -ms-transition:.3s;
    -moz-transition:.3s;
    -webkit-transition:.3s;
    transition:.3s;
    box-shadow: 12px 12px #0D1643;
}


