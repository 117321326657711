
.nav-menu {
    background-color: #0D1643;
    width: 50%;
    height: 70vh;
    display: flex;
    justify-content: center;
    position: fixed;
    right: -100%;
    transition: 850ms;
    top: 65px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .nav-menu.active {
    right: 0;
    transition: 350ms;
  }
  
  .nav-menu.active {
    right: 0;
    transition: 350ms;
  }

  .nav-main-menu-text {
    display: flex;
    justify-content: start;
    align-items: center;
    list-style: none;
    height: 60px;
    color: white;
  }
  .dropdownIcon {
    position: absolute;
    right: 3%;
  }

  
  .nav-main-menu-text a {
    text-decoration: none;
    /* font-size: 1em; */
    font-size: 1rem !important;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
  } 
  
  
  .nav-main-menu-items {
    width: 100%;
  }
  
  
  span {
    margin-left: 15px;
  }

  .sub-menu-icon {
      color: white;
      float: right;
  }


  .nav-sub-menu-text {
    justify-content: start;
    align-items: center;
    list-style: none;
    height: 60px;
  }
  
  .nav-sub-menu-text a {
    text-decoration: none;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  
  .subitem-container {
    display: block;
    background-color: #0D1643;
    height: auto;
    position: relative;
  }

  
@media only screen and (min-width: 1000px) {
  .nav-menu {
    display: none;
  }
  .nav-menu :active {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .nav-menu {
    width: 75%;
  }
  
}