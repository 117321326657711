#color-section {
    height: auto;
    min-height: 500px;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(228,229,232,1) 0%,  rgba(13,22,67,0.7) 100%);
    display: flex;
}
#text-section{
    padding-bottom: 20px;
}

#mobileLogo {
    height: 100px;
    width: 100px;
    margin: 20px 20px 10px 20px;
    border-radius: 15px;
    display: none;
}
#download-info-wrap {
    width: 55%;
}

#download-header{
    font-size: 3rem;
    font-weight: 700;
    text-align: left;
    margin-left: 180px;
    margin-bottom: 20px;
    padding-top: 100px;
    color: black;
}

#download-body{
    font-size: 1.7rem;
    font-weight: 400;
    text-align: left;
    margin-left: 180px;
    max-width: 800px;
    color: black;
    margin-bottom: 0px;
}

#badge-wrap{
    margin-bottom: 20px;
    max-width: 900px;
    justify-content: space-evenly;
    margin-top: 50px;
}

.badge-logo{
    width: 180px;
    margin: 30px 50px auto;
}

.badge-button:hover,
.badge-button:focus {
  position: relative;
  top: 2px;
  opacity: 0.7;
}

.openapp {
    font-weight: bold;
    font-size: 1.5rem;
    border: black 3px solid;
    background-color: transparent;
    border-radius: 10px;
    text-align: center;
    color: black;
    padding: 5px 25px;
    background: white;
}
  
.openapp:hover {
    border-color: rgb(247, 132, 55);
    color: rgb(247, 132, 55);
}

.openapp:active {
    background-color: gray;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

#app-label {
    text-align: center;
    font-size: 1.2rem;
}

#have-app{
    margin-top: 30px;
    max-width: 900px;
    padding-bottom: 20px;
}
#phone-display-section{
    width: 45%;
}
#phonepromo {
    height: 420px;
    float: left;
    margin-top: 50px;
    /* margin-left: 25px; */
}
#phonelogo{
    display: none;
}

#text-header {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin-top: 30px;
    color: black;
    margin-bottom: 20px;
}

#text-body {
    font-size: 1.7rem;
    font-weight: 400;
    text-align: center;
    color: black;
}

.sms-form-label {
    text-align: right;
    font-size: 1.7rem;
}
#sms-form {
    margin: 0 auto;
}
.send-sms-button {
	background:linear-gradient(to bottom, #0d1643 5%, #08122b 100%);
	background-color:#25457d;
	border-radius: 15px;
	border: 3px solid #08122b;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:20px;
	padding:10px 20px;
	text-decoration:none;
    margin-top: 20px;
}
.send-sms-button:hover {
	border-color: rgb(247, 132, 55);
}
.send-sms-button:active {
	position:relative;
	top:1px;
}

.send-sms-button:disabled,
.send-sms-button[disabled] {
    border: 2px solid #999999;
    background-color: #cccccc;
    background: #999999;
}


#hover-message{
    color: red;
    margin: 10px auto;
}

#alertbox{
    width: 50%;
    margin: 20px auto;
}


@media only screen and (max-width: 1400px) {
    #download-header{
        margin-left: 100px;
    }
    #download-body{
        margin-left: 100px;
    }
    #phonepromo {
        height: 350px;
        margin-top: 100px;
        float: none;
    }
}

@media only screen and (max-width: 1200px) {
    #download-header{
        margin-left: 50px;
    }
    #download-body{
        margin-left: 50px;
    }
    #phonepromo {
        height: 285px;
        margin-left: 0px;
        margin-top: 120px;
    }
    
    .badge-logo{
        width: 180px;
        margin: 30px 30px auto;
    }
    #badge-wrap{
        margin-top: 10px;
    }
}

@media only screen and (max-width: 920px) {

    #download-info-wrap {
        width: 60%;
    }
    #phone-display-section{
        width: 40%;
    }
    #phonepromo {
        display: none;
    }
    #phonelogo{
        display: block;
        height: 350px;
        margin: 80px auto;
    }
    #download-header{
        padding-top: 50px;
    }
}

@media only screen and (max-width: 600px) {
    #download-header{
        margin-left: 20px;
        padding-top: 30px;
    }
    #download-body{
        margin-left: 20px;
    }
    #download-info-wrap {
        width: 100%;
    }
    #phone-display-section{
        display: none;
    }

    #mobileLogo{
        display: inline;
    }

    #text-section{
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
    }

    #alertbox{
        width: 100%;
    }
    #badge-wrap {
        margin-top: 0px;
    }
}