/* * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

.footer-container {
    background-color: #0d1643;
    padding: 2rem 0 4rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-left {
    flex: 80%;
    color: #ffff;
}

.footer {
    background-color: #0d1643;
    color: #ffffff;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    padding-top: 0;
    padding-bottom: 4rem;
}

.footer-heading {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    text-align: left;
    max-width: 150px !important;
    font-size: 1rem;
}

    .footer-heading h2 {
        margin-bottom: 1rem;
        align-content: center;
        display: flex;
        flex-direction: column;
        font-size: 1.7em;
    }

    .footer-heading a {
        color: #ffffff;
        text-decoration: none;
        align-content: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.75rem;
        font-size: 1.1em;
    }

        .footer-heading a:hover {
            color: rgb(247, 132, 55);
            transition: 0.3s ease-out;
        }

.form-input {
    width: 200px;
    flex-direction: row;
}

.footer-email-form h2 {
    margin-bottom: 2rem;
    font-size: 20px;
}

.footer-email-form {
    display: inline-block;
    flex-direction: column;
    color: #ffff;
    max-width: 450px;
    text-align: left;
}

    .footer-email-form h2 {
        margin-bottom: 6px;
    }

.footer-email {
    border-radius: 4px;
    outline: none;
    border: none;
    padding-left: 0.5rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.btn-outline {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
    padding: 8px 20px;
    font-weight: bold;
    font-size: 15px;
    border-radius: 3px;
    transition: all 0.3s ease-out;
    width: 130px !important;
    margin-right: 6px;
}

    .btn-outline:hover {
        color: rgb(247, 132, 55);
        border-color: rgb(247, 132, 55);
    }

.copyright-info {
    padding: 5px;
}

.img-row a {
    width: auto;
}
    .img-row a img {
        max-width: 250px;
    }

@media all and (max-width: 1650px) {
    .footer-heading {
        font-size: 0.8rem;
    }
}

@media all and (max-width: 1350px) {
    .footer-heading {
        font-size: 0.7rem;
    }

}

@media all and (max-width: 1200px) {
    .img-row a img {
        max-width: inherit;
    }
}

@media all and (max-width: 991px) {
    .img-row a img {
        max-width: 200px;
    }
}

@media all and (max-width: 768px) {
    .img-row a img {
        max-width: 150px;
    }
}
 */

 * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

.footer-container {
    background-color: #0d1643;
    padding: 2rem 0 4rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-left {
    flex: 80%;
    color: #ffff;
}

.footer {
    background-color: #0d1643;
    color: #ffffff;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    padding-top: 0;
    padding-bottom: 4rem;
}

.footer-heading {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    text-align: left;
    max-width: 150px;
    font-size: 1rem;
}

.footer-heading h2 {
    margin-bottom: 1rem;
    align-content: center;
    display: flex;
    flex-direction: column;
    font-size: 1.7rem;
}

.footer-heading a {
    color: #ffffff;
    text-decoration: none;
    align-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.75rem;
    font-size: 1.1em;
}

.footer-heading a:hover {
    color: rgb(247, 132, 55);
    transition: 0.3s ease-out;
}

.form-input {
    width: 200px;
    flex-direction: row;
}

.footer-email-form h2 {
    margin-bottom: 2rem;
    font-size: 1.25rem !important; /* Changed from 20px to 1.25rem */
}

.footer-email-form input {
    font-size: 1.25rem !important; /* Changed from 20px to 1.25rem */
}

.footer-email-form {
    display: inline-block;
    flex-direction: column;
    color: #ffff;
    max-width: 450px;
    text-align: left;
}

.footer-email {
    border-radius: 4px;
    outline: none;
    border: none;
    padding-left: 0.5rem;
    font-size: 1rem; /* Changed from 1rem to 1rem */
    margin-bottom: 1rem;
    justify-content: space-between;
}

.btn-outline {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
    padding: 8px 20px;
    font-weight: bold;
    font-size: 1rem; /* Changed from 15px to 1rem */
    border-radius: 3px;
    transition: all 0.3s ease-out;
    width: 130px;
    margin-right: 6px;
}

.btn-outline:hover {
    color: rgb(247, 132, 55);
    border-color: rgb(247, 132, 55);
}

.copyright-info {
    padding: 5px;
    font-size: 0.75rem !important; /* Changed from 12px to 0.75rem */
}

.img-row a {
    width: auto;
}

.img-row a img {
    max-width: 250px;
}

@media all and (max-width: 1650px) {
    .footer-heading {
        font-size: 0.8rem;
    }
}

@media all and (max-width: 1350px) {
    .footer-heading {
        font-size: 0.7rem;
    }
}

@media all and (max-width: 1200px) {
    .img-row a img {
        max-width: inherit;
    }
}

@media all and (max-width: 991px) {
    .img-row a img {
        max-width: 200px;
    }
}

@media all and (max-width: 768px) {
    .img-row a img {
        max-width: 150px;
    }
}
